@import "../Tools/colors-vars";
@import "../responsive-vars";

.recommender {
  img {
    text-indent: 100%;
    &:not([src]):not([srcset]) {
      visibility: hidden;
    }
  }

  .price {
    color: $recommender-price-color;
  }

  .btn-prev, .btn-next {
    top: 37%;
    @media (min-width: $break-md) {
      top: 39%;
    }
  }
  .btn-prev {
    left: -20px;
    @media (min-width: $break-md) {
      left: -30px;
    }
  }
  .btn-next {
    right: -20px;
    @media (min-width: $break-md) {
      right: -30px;
    }
  }
}