.lastViewedProducts {
  margin-bottom: 10px;
  .recommender-header {
    margin-top: 0 !important;
    text-align: center;
    text-transform: uppercase !important;
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 15px !important;
    }
  }
}
.old-website {
  font-size: inherit !important;
}