.recommender img {
  text-indent: 100%;
}

.recommender img:not([src]):not([srcset]) {
  visibility: hidden;
}

.recommender .price {
  color: #2196F3;
}

.recommender .btn-prev,
.recommender .btn-next {
  top: 37%;
}

@media (min-width: 768px) {
  .recommender .btn-prev,
  .recommender .btn-next {
    top: 39%;
  }
}

.recommender .btn-prev {
  left: -20px;
}

@media (min-width: 768px) {
  .recommender .btn-prev {
    left: -30px;
  }
}

.recommender .btn-next {
  right: -20px;
}

@media (min-width: 768px) {
  .recommender .btn-next {
    right: -30px;
  }
}


.recommender .splide__arrow {
  padding: 13px 15px;
}

.recommender .title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.recommender .labels label {
  padding: 2px 4px;
  font-size: 8px;
}

@media (max-width: 320px) {
  .recommender .recommendation .rating {
    display: block !important;
  }

  .recommender .recommendation .rating .review-stars {
    margin: auto;
  }

  .recommender .recommendation .rating span {
    display: block;
    text-align: center;
    margin: 0 !important;
  }
}


.lastViewedProducts {
  margin-bottom: 10px;
}

.lastViewedProducts .recommender-header {
  margin-top: 0 !important;
  text-align: center;
  text-transform: uppercase !important;
  font-size: 12px;
}

@media (min-width: 768px) {
  .lastViewedProducts .recommender-header {
    font-size: 15px !important;
  }
}

.old-website {
  font-size: inherit !important;
}


