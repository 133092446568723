.recommender{
  .splide__arrow {
    padding: 13px 15px;
  }

  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .labels label {
    padding: 2px 4px;
    font-size: 8px;
  }

  .recommendation {
    @media (max-width: 320px) {
      .rating {
        display: block !important;
        .review-stars {
          margin: auto;
        }
        span {
          display: block;
          text-align: center;
          margin: 0 !important;
        }
      }
    }
  }
}